import { format, parse, isValid } from 'date-fns';
import {Auth, Signer} from "aws-amplify";
import {configData} from "../config.js";

/**
 * Utility functions used by several files
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */


export function getDateFormats() {
  return [
    "dd-MMM-yyyy", "dd-MMMM-yyyy", "yyyy-MM-dd", "yyyy/MM/dd", "dd-MM-yyyy", "dd/MM/yyyy",
    "yyyy-MM-dd'T'HH:mm:ss", "yyyy-MM-dd HH:mm:ss", "yyyy-MM-dd HH:mm:ss.SSSSSS", "yyyy-MM-dd'T'HH:mm:ssxxxxx"
  ];
}

export function parseDate(dateObject) {
  if (typeof (dateObject) === "string") {
    for (const format of getDateFormats()) {
      const parsedDate = parse(dateObject, format, new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }
  }
  if (typeof (dateObject) === "number") {
    return new Date(dateObject);
  }
  if (dateObject instanceof Date) {
    return dateObject;
  }
  return null;
}

/**
 * Formats a data value
 * @param {*} dateObject a date object, number, string or null
 * @param {*} includeTime true = date and  time, false = just date
 * @returns a formated string of the date
 */
 export function formatDate(dateObject, includeTime = false) {
    const retValue = (dateObject instanceof Date) ? dateObject : parseDate(dateObject);
    if (retValue instanceof Date) {
      if (includeTime) {
        return format(retValue, "dd-MMM-yyyy HH:MM:ss");
      }
      return format(retValue, "dd-MMM-yyyy");
    }
    return retValue;
  }

/**
 * coverts a date string to a date object
 * @param {*} dateObject a date object, number, string or null
 * @returns either null or a data object
 */
 export function convertToDate(dateObject) {
  let retValue = dateObject;
  if (typeof (retValue) === "string") {
    retValue = new Date(Date.parse(retValue));
  }
  if (typeof (retValue) === "number") {
    retValue = new Date(retValue);
  }
  if (retValue instanceof Date) {
    return retValue;
  }
  return null;
}

/**
 * Truncates the time and time zone from a date time
 * @param {*} value 
 * @returns a Date with the time and time zone truncated
 */
export function truncateDate(value) {
  return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
}

/**
 * Join a list of values into a string with a different final seperator
 * @param {*} listToJoin A list of values to join 
 * @param {*} seperator The seperator for all but the last entry (default ", ")
 * @param {*} lastSeperator The seperator for the final entry (default " and ")
 * @returns A string of the combined list values
 */
export function joinWithDifferentLastSeperator(listToJoin, seperator = ", ", lastSeperator = " and "){
  let listString = listToJoin.join(seperator);
  // replace the last seperator with " and "
  const n = listString.lastIndexOf(seperator);
  if (n >= 0) {
    listString = listString.substring(0, n) + lastSeperator + listString.substring(n + seperator.length);
  }
  return listString;
}

/**
 * Filters a list to remove empty (null or undefined or "" or "<whitespace only>) values and then joins into a string
 * @param {*} listToJoin A list of values to filter and join 
 * @param {*} seperator The seperator for all but the last entry (default ", ")
 * @param {*} lastSeperator The seperator for the final entry (default ", ")
 * @returns A string of the combined list values with empty values removed
 */
export function joinWithNoEmptyValues(listToJoin, seperator = ", ", lastSeperator = ", "){
  let filteredListToJoin = listToJoin.filter((x) => ((x!==null) && (x!==undefined) && (x.trim().length > 0)));

  return joinWithDifferentLastSeperator(filteredListToJoin, seperator, lastSeperator);
}


/**
 * Removes the roche_ prefix from usernames
 * @param {*} value The username
 * @returns The username with the prefix removed if present
 */
export function cleanUserName(value) {
  let retVal = value;
  const unwantedPrefix = "roche_";
  if (retVal.startsWith(unwantedPrefix)) {
    retVal = retVal.slice(unwantedPrefix.length);
  }
  return retVal;
}

/**
 * Signs a request to send to the API
 * @param {*} url The url to send to
 * @param {*} method The HTTP Method e.g. GET, POST
 * @param {*} service The service this is being sent to
 * @param {*} region The AWS region
 * @param {*} data The data of the request
 * @returns A cryptographic signature
 */
export async function signRequest(url, method, service, region, data) {
  const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());

  const params = { data, method, url };
  const credentials = {
    secret_key: essentialCredentials.secretAccessKey,
    access_key: essentialCredentials.accessKeyId,
    session_token: essentialCredentials.sessionToken,
  };
  const serviceInfo = { region, service };

  // Signer.sign takes care of all other steps of Signature V4
  const s = Signer.sign(params, credentials, serviceInfo)
  return s
}

/**
 * Signs a request and submits it to the API
 * @param {*} url The url to send to
 * @param {*} init The distionary of fetch settings
 * @param {*} timeoutWarning Ho many milliseconds to allow before assuming a timeout (default 29000)
 * @returns The reponse to the fetch call
 */
export async function fetchSigned(url, init={"method": "GET"}, timeoutWarning=29000) {
  const startTime = Date.now();
  try {      
      if (!("method" in init)) {
        init.method = "GET";
      }
      let data = "";
      if ("body" in init) {
        data = init["body"];
      }
      const signedRequest = await signRequest(
        url, 
        init.method,
        configData.AWS_SERVICE, 
        configData.AWS_REGION,
        data);
      init.mode = "cors";
      init.cache = "no-cache";
      init.referrer = "client";

      if ("headers" in init) {
        init.headers = Object.assign({}, init.headers, signedRequest.headers);
      } else {
        init.headers = signedRequest.headers;
      }
      
      const response = await fetch(signedRequest.url, init);
      return response;
  } catch (e) {
    const duration = Date.now() - startTime; 
    if (duration > timeoutWarning) {
      console.log(`Likely timeout after ${duration} milliseconds with ${init.method} from ${url}: ${e}`);
      e.message = `Timeout (${duration}ms) when calling the API.`;
      throw e;
    } else {
      console.log(`Error fetching with ${init.method} from ${url}: ${e}`);
      throw e;
    }
  }
}

/**
 * Encodes a string to base 64
 * @param {*} input The string to encode
 * @returns The base64 encoded string
 */
export function b64Encode(input) {
  const buff = new Buffer(input);
  return buff.toString('base64');
}

/**
 * Decodes a string from base 64
 * @param {*} input The base 64 data to decode
 * @returns The decoded string
 */
export function b64Decode(input) {
  const decodebuff = new Buffer(input, 'base64');
  return decodebuff.toString('ascii');
}

/**
 * Gets the refDataId for a refernce data item description
 * @param {*} value The description of the refernce data item (may have the deprecated sufix at the end)
 * @param {*} refdataList The reference data list to search for this item
 * @returns The refDataId is found otherwise null
 */
export function getIdForRefData(value, refdataList) {
  let retVal = null;
  const searchText = value.endsWith(configData.REFDATA_DEPRECATED)? 
                        value.slice(0, -configData.REFDATA_DEPRECATED.length)
                        : value;

  const refDataItem = refdataList.find(({ description }) => description === searchText);
  //get the record
  if (refDataItem !== undefined) {
    retVal = refDataItem.refListId;
  }
  return retVal;
}

/**
 * Gets the description for a refernce data item refDataId
 * @param {*} value The refDataId of the refernce data item 
 * @param {*} refdataList The reference data list to search for this item
 * @returns The description, with the deprecated suffix if deprecated
 */
export function getDescriptionForRefDataId(id, refdataList) {
  let retVal = null;

  const refDataItem = refdataList.find(({ refListId }) => refListId === id);
  //get the record
  if (refDataItem !== undefined) {
    retVal = refDataItem.description + (refDataItem.isActive?"":configData.REFDATA_DEPRECATED);
  }
  return retVal;
}

/**
 * Converts a camelCase string to Title Case
 * @param {*} camelCaseText the camelCaseText
 * @returns The string converted to Title Case
 */
export function convertCamelCaseToTitleCase(camelCaseText) {
  const result = camelCaseText.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/** Creates a message with an embedded time signal
* @param {*} message The text of the message 
 * @param {*} seperator The seperator for the message from the time (default ¦)
* @returns the timed mesage
*/
export function createTimedMessage(message, seperator = "¦") {
 return message + seperator + Date.now();
}

/** Extracts the message from a timed message
* @param {*} message The text of the message that should contain a integer tick millisecond value at the end
 * @param {*} seperator The seperator for the message from the time (default ¦)
* @returns the message without the time section
*/
export function extractTimedMessage(message, seperator = "¦") {
  if (message === null) {
    return null;
  }
  //parse the date off the end of the string
  let retVal = message;
  const pos = retVal.lastIndexOf(seperator);
  if (pos !== -1) {
    retVal = retVal.slice(0, pos);
  } 
  return retVal;
 }

/**
 * Checks if a message  fed through a property is recent, or old
 * @param {*} message The text of the message that should contain a interger tick millisecond value at the end
 * @param {*} milliseconds The number of milliseconds that is considered recent (default 1000)
 * @param {*} seperator The seperator for the message from the time (default ¦)
 * @returns true if the message is recent, otherwise false
 */
export function isRecentMessage(message, milliseconds = 1000, seperator = "¦") {
  if (message === null) {
    return null;
  }
  //parse the date off the end of the string
  const regex = `${seperator}([0-9]+)$`;
  const arr = message.match(regex);
  if (arr === null) {
    return false;
  }
  //convert it to an int
  const num = parseInt(arr[1], 10);
  // true if within the number of milliseconds
  return (Date.now() < num + milliseconds);
}
